import Vue from 'vue';

//import app from "../vue/app.vue";

var app = require("../vue/app.vue").default;
new Vue({
    el: '#app',
    render: h => h(app),

})

