<template>
  <div>
    <div class="container">
      <div class="v-aligne">
        <span class="material-icons icon-home">cottage</span>
        <div>Credit Remboursé :</div>
        <div class="debt mb-2">{{ debt }} %</div>
        <div class="progress mb-3">
          <div class="progress-bar" role="progressbar" :style="'width: '+debt+'%'" :aria-valuenow="debt" aria-valuemin="0" aria-valuemax="100"></div>
        </div>
        <div class="mt-1 mb-1">Prochain Palier {{ nextPalier }}% : {{ palierDate }}</div>
        <div>Propriétaire de {{ m2 }} m²</div>
        <div class="mt-1 mb-1">Prochain Palier {{ nextPalierM2 }} m² : {{ palierM2 }}</div>

        <div class="row pl-1 fixed-bottom m-3">
          <div class="row container">
            <template v-for="(v,i) in 190">
              <div class="square" :class="{'actif':(m2>=v)}"></div>
            </template>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment'

require('moment/locale/fr');

export default {
  name: "app",
  data() {
    return {
      date1: new Date("2021-02-12 10:00:00"),
      date2: new Date("2056-02-12 10:00:00"),
      palierDate: "",
      palierM2: "",
      nextPalier: 1,
      nextPalierM2: 1,
      debt: 0,
      inter: false,
      m2: 0
    }
  },
  methods: {
    calcDate() {
      // this.date1 = new Date("2021-03-12 19:16:40");
      //let now = new Date("2021-07-20 06:55:00");
      //let now = new Date("2056-02-12 10:00:00");
      //let now = new Date("2021-04-20 17:42:00");
       let now = new Date();
      let dif = (now - this.date1) / 1000;
      //dif = 11044512;
      let diff_tot = (this.date2 - this.date1) / 1000;
      let p = (dif / diff_tot) * 100;
      let u = (1000 / diff_tot) * 100;
      this.nextPalier = Math.ceil(p);
      let u1 = (diff_tot / 100) * this.nextPalier;
      this.nextPalierM2 = Math.ceil((dif / diff_tot) * 190);
      //let um2 = (diff_tot / 190) * this.nextPalierM2;
      let um2 = (diff_tot / 190)
      this.m2 = Math.round((dif / um2) * 1000) / 1000;

      let pe = this.date1.getTime() + (u1 * 1000);
      let pem2 = this.date1.getTime() + ((um2) * this.nextPalierM2 * 1000);
      //console.log(pe);

      this.debt = p;
      this.debt = Math.round(this.debt * 100000000) / 100000000;
      //let nd = new Date(1000 / diff_tot);

      let nd = new Date(pe);
      let momdate = moment(nd).format("DD/MM/YYYY");
      this.palierDate = moment(nd).format("LLL");
      this.palierM2 = moment(new Date(pem2)).format("LLL");
      //console.log(dif, diff_tot, p, {u: u, u1: u1}, {date: nd, date2: momdate});
    }
  }, mounted() {
    this.calcDate();
    clearInterval(this.inter);
    this.inter = setInterval(() => {
      this.calcDate();
    }, 1000)
    //this.calcDate();
  }
}
</script>
<style scoped>
.container {
  margin: auto;
  max-width: 500px;
  text-align: center;
  font-weight: 500;
}

.v-aligne {
  margin-top: calc(50vh - 150px);

  top: 100px;
}

.debt {
  font-size: 2.3em;
}

.icon-home {
  font-size: 4em;
}

.square {
  margin: 2px;
  height: 10px;
  width: 10px;
  border: white solid 1px;
}

.actif {
  background-color: white;
}
</style>